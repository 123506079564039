import React from 'react';
import { graphql } from 'gatsby';
import { StaticQuery } from "gatsby";
// import { graphql } from 'react-apollo';
// import gql from 'graphql-tag';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ObjectCard from '../components/ObjectCard';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const CARDS_PER_PAGE = 6;

const TourCompanies = () => (
  <StaticQuery
    query={query}
    render={data => (
      <Layout>
        <SEO 
          title="Ghost Tours"
          description="Activities to experience and learn about spooky places."
        />
        <Typography variant="h2">
          Ghost Tours
        </Typography>
        <section>
          <Grid container spacing={2}>
            {data.gcms.tourCompanies.map(tourCompany => (
              <ObjectCard
                key={tourCompany.id}
                title={tourCompany.name}
                link={`/tourcompany/${tourCompany.slug}`}
                image={tourCompany.image}
                relatedPlaceCount={tourCompany.places.length}
                relatedPersonCount={tourCompany.people.length}
                relatedLoreCount={tourCompany.lores.length}
                relatedBookCount={0}
                relatedInvestigationCount={0}
                relatedTourCompanyCount={0}
              />
            ))}
          </Grid>

          
        </section>
      </Layout>
    )}
  />
);

const query = graphql`
  query {
    gcms {
      tourCompanies(orderBy: publishedAt_DESC) {
        id
        name
        slug
        image {
          handle
          title
        }
        people {id}
        places {id}
        lores {id}
      }
      tourCompaniesConnection {
        aggregate {
          count
        }
      }
    }
  }
`;

export default TourCompanies;



// const TourCompanies = ({
//     data: { loading, error, tourCompanies, tourCompaniesConnection, networkStatus },
//     loadMoreCards,
//   }) => {
//     if (error) return <h1>Error fetching ghost tours!</h1>;
//     if (tourCompanies && tourCompaniesConnection) {
//       const areMorePosts = tourCompanies.length < tourCompaniesConnection.aggregate.count;
//       return (
//         <React.Fragment>
//           <Typography variant="h2">
//             Ghost Tours
//           </Typography>
//           <section>
//               <Grid container spacing={2}>
//                   {tourCompanies.map(tourCompany => (
//                     <ObjectCard 
//                       title={tourCompany.name}
//                       link={`/tourcompany/${tourCompany.slug}`}
//                       image={tourCompany.image}
//                       relatedPlaceCount = {tourCompany.places.length}
//                       relatedPersonCount = {tourCompany.people.length}
//                       relatedLoreCount = {tourCompany.lores.length}
//                       relatedBookCount = {0}
//                       relatedInvestigationCount = {0}
//                       relatedTourCompanyCount = {0}
//                     />
//                   ))}
//               </Grid>

//               <Grid container justify="center" style={{marginTop: '16px'}}>
//                 <Grid item >
//                   {areMorePosts ? (
//                     <Button 
//                       variant="contained" 
//                       color="primary"
//                       disabled={loading}
//                       onClick={() => loadMoreCards()}
//                     >
//                       {loading ? 'Loading...' : 'Show More Ghost Tours'}
//                     </Button>
//                   ) : (
//                     ''
//                   )}
//                 </Grid>
//               </Grid>
//           </section>
//         </React.Fragment>
//       );
//     }
//     return <h2>Loading ghost tours...</h2>;
//   };

// export const query = gql`
//   query tourCompanies($first: Int!, $skip: Int!) {
//     tourCompanies(orderBy: publishedAt_DESC, first: $first, skip: $skip) {
//       id
//       name
//       slug
//       image {
//         handle
//         title
//       }
//       people {id}
//       places {id}
//       lores {id}
//     }
//     tourCompaniesConnection {
//       aggregate {
//         count
//       }
//     }
//   }
// `;

// export const queryVars = {
//   skip: 0,
//   first: CARDS_PER_PAGE,
// };

// export default graphql(query, {
//   options: {
//     variables: queryVars,
//   },
//   props: ({ data }) => ({
//     data,
//     loadMoreCards: () => {
//       return data.fetchMore({
//         variables: {
//           skip: data.tourCompanies.length,
//         },
//         updateQuery: (previousResult, { fetchMoreResult }) => {
//           if (!fetchMoreResult) {
//             return previousResult;
//           }
//           return Object.assign({}, previousResult, {
//             tourCompanies: [...previousResult.tourCompanies, ...fetchMoreResult.tourCompanies],
//           });
//         },
//       });
//     },
//   }),
// })(TourCompanies);